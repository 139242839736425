import React from "react";
import {
  Table,
  Modal,
  Button,
  Form,
  Input,
  Select,
  Space,
  Tag,
  DatePicker,
  Radio,
} from "antd";
import { useMobile } from "../../hooks";
import { useState, useEffect, useRef } from "react";
import { fetchData, putData, postData } from "../../endpoints";

import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
import { useConfig } from "../../hooks/useConfig";
import { CloseCircleFilled } from "@ant-design/icons";
const privilage = Cookies.get("privilege") ?? full;

function Translators() {
  const isMobile = useMobile();
  const [dataSource2, setdataSource2] = useState([]);
  const [isLoadingUpcoming, setisLoadingUpcoming] = useState(false);
  const [config, setConfig] = useState([]);
  const { getUserConfig } = useConfig();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});
  const [selectedappointment, setselectedappointment] = useState({});
  const [reply, setReply] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);

  const getConfig = async () => {
    getUserConfig();
    var res = JSON.parse(localStorage.getItem("config"));
    setConfig(res.data.data);
  };
  const columns_upcoming = [
    {
      title: "Appointment ID",
      dataIndex: "appointment",
      key: "appointment",
      // width: "20%",
      render: (u) => <p>{u ? u.id : ""}</p>,
    },
    {
      title: (
        <div style={{ display: "flex", justifyContent: "space-around" }}>
          <div>Patient</div>{" "}
        </div>
      ),
      dataIndex: "appointment",
      key: "appointment",
      // width: "15%",
      render: (u) => (
        <a
          href={
            privilage.indexOf(access.patientManegment.showPatient) > -1 &&
            "/patients/" + u.patientId
          }
          style={{ color: "black" }}
        >
          {u.patientId}
        </a>
      ),
    },
    {
      title: "Type",
      dataIndex: "appointment",
      key: "appointment",
      // width: "20%",
      render: (u) =>
        u.locationType && (
          <div>
            {u.locationType.charAt(0).toUpperCase() + u.locationType.slice(1)}{" "}
          </div>
        ),
    },
    {
      title: "Date",
      dataIndex: "appointment",
      key: "appointment",
      // width: "15%",
      // ...getColumnSearchProps_upcoming("startedAt"),
      render: (u) =>
        new Date(u.startedAt) > new Date() ? (
          <p>
            {new Date(u.startedAt)
              .toISOString()
              .replace(/^([^T]+)T([^\.]+)(.+)/, "$1 $2")
              .slice(0, -3)}
            <br />
            {parseInt(
              (new Date(u.startedAt) - new Date()) / (1000 * 60 * 60 * 24),
              10
            ) + "  "}
            Days left
          </p>
        ) : (
          <p>
            {new Date(u.startedAt)
              .toISOString()
              .replace(/^([^T]+)T([^\.]+)(.+)/, "$1 $2")
              .slice(0, -3)}
          </p>
        ),
    },
    {
      title: "Language",
      dataIndex: "lang",
      key: "lang",
      render: (u) =>
        u ? <div>{u.charAt(0).toUpperCase() + u.slice(1)}</div> : <div>-</div>,
    },
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (u) =>
        u ? (
          <div style={u == "pending" ? { color: "red" } : {}}>
            {camelize(u)}
          </div>
        ) : (
          <div>-</div>
        ),
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      render: (u) => (u ? <div>{u}</div> : <div>-</div>),
    },
    // privilage.indexOf(access.todoList.addReply) > -1 && {
    //   title: "",
    //   dataIndex: "",
    //   key: "x",
    //   render: (u) => (
    //     <Button
    //       type="primary"
    //       size={isMobile ? "small" : "default"}
    //       onClick={() => {
    //         console.log(u);
    //         setselectedappointment(u);
    //         setReply(true);
    //       }}
    //     >
    //       Reply
    //     </Button>
    //   ),
    // },
  ];
  function camelize(str) {
    return str
      .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
        return index === 0 ? word.toUpperCase() : word.toLowerCase();
      })
      .replace(/\s+/g, "");
  }
  async function getupcoming(page = 1, relod = false) {
    let date = new Date();

    let year = date.getFullYear();
    let month = date.getMonth() + 1;
    let day = date.getDate() > 2 ? date.getDate() - 1 : 29;
    setisLoadingUpcoming(true);

    if (dataSource2.length < (page - 1) * 15 + 2 || relod) {
      var res = await fetchData(
        "admin/appointment-translator?deletedAt=false&includes[]=appointment&includes[]=transaction&perPage=15&page=" +
          // `admin/appointment?deletedAt=false&includes[]=translators&startedAtGte=${year}-${month}-${day}&sortKey=startedAt&includes[]=event&includes[]=approvedBy&includes[]=patient&sortValue=1&perPage=15&page=` +
          page
      );
      var temp = dataSource2;
      if (relod) {
        temp = [];
      }
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setdataSource2(
            temp
              .filter((a, i) => {
                return a.id;
              })
              .concat([...res.data.data])
          );
        } else {
          setdataSource2(
            temp
              .filter((a, i) => {
                return a.id;
              })
              .concat([
                ...res.data.data,
                { appointment: { startedAt: new Date() } },
              ])
          );
        }
      } else {
        Modal.error({
          title: res,
        });
      }
    }
    setisLoadingUpcoming(false);
  }

  // async function fetchEvents(page = 1, isReload = false) {
  //   var temp = isReload ? [] : data;
  //   setisLoadingUpcoming(true);
  //   if (data.length < (page - 1) * 15 + 2 || isReload) {
  //     var res = await fetchData(
  //       "admin/appointment-event/?deletedAt=false&perPage=15&page=" + page
  //     );
  //     res.data.data.map((tempdata, index) => {
  //       tempdata["key"] = index;
  //     });
  //     if (res.status === 200) {
  //       if (res.data.data.length < 15) {
  //         setdata_appointment(
  //           temp
  //             .filter((a, i) => {
  //               return a.title;
  //             })
  //             .concat(res.data.data)
  //         );
  //       } else {
  //         setdata_appointment(
  //           temp
  //             .filter((a, i) => {
  //               return a.title;
  //             })
  //             .concat([
  //               ...res.data.data,
  //               { createdAt: new Date().toString(), locationTypes: [] },
  //             ])
  //         );
  //       }
  //     } else {
  //       Modal.error({
  //         title: res,
  //       });
  //     }
  //   }
  //   setisLoadingUpcoming(false);
  // }
  useEffect(() => {
    getConfig();
    if (privilage.indexOf(access.todoList.showappointment) > -1) {
      getupcoming();
    }
    // if (privilage.indexOf(access.todoList.indexPatient) > -1) {
    //   getpatientmodal();
    // }
    // if (privilage.indexOf(access.todoList.indexEvent) > -1) {
    //   fetchEvents();
    // }
  }, []);

  return (
    <>
      <div
        style={{
          minHeight: "150vh",
          minWidth: "100%",
          backgroundColor: "white",
        }}
      >
        {/* <div>Translators</div> */}
        {privilage.indexOf(access.todoList.showappointment) > -1 && (
          <div
            style={
              isMobile
                ? {
                    padding: "40px 40px",
                    textAlign: "center",
                    margin: "auto",
                    minHeight: "50%",
                  }
                : {
                    padding: "0px 70px",
                    textAlign: "center",
                    margin: "auto",
                    minHeight: "50%",
                  }
            }
          >
            <div
              style={
                isMobile
                  ? {
                      display: "flex",
                      justifyContent: "center",
                      padding: "40px 0px",
                      fontSize: "18px",
                    }
                  : {
                      display: "flex",
                      justifyContent: "start",
                      padding: "40px 0px",
                      fontSize: "24px",
                    }
              }
            >
              <div style={{ textAlign: "left" }} className="d-flex flex-column">
                {" "}
                <strong>Need Interpreter</strong>
                <div
                  style={{
                    fontWeight: "400",
                    fontSize: "16px",
                    marginTop: "20px",
                  }}
                >
                  View, track, and manage interpreter requests for upcoming
                  appointments.
                </div>
              </div>
            </div>
            <Table
              scroll={{ x: "auto" }}
              size="small"
              columns={columns_upcoming}
              dataSource={dataSource2}
              loading={isLoadingUpcoming}
              pagination={{
                onChange: async (page) => {
                  await getupcoming(page);
                },

                pageSize: 15,
              }}
            />
          </div>
        )}
      </div>
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={null}
      >
        <div className="d-flex flex-row align-items-center gap-2">
          <CloseCircleFilled style={{ color: "#ff4d4f" }} />
          <p style={{ fontWeight: "700", margin: "0" }}>
            {modalData?.message || "Something went wrong!"}
          </p>
        </div>
      </Modal>

      {/* <Modal
        title=""
        open={reply}
        // okText="Send"
        footer={null}
        onCancel={() => {
          setReply(false);
          form.resetFields();
        }}
      >
        <strong
          style={{
            textAlign: "center",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            fontSize: "24px",
          }}
        >
          Send reply
        </strong>

        <Form
          form={form}
          // layout="vertical"
          onFinish={async (values) => {
            setisLoadingUpcoming(true);
            var temp = {
              purchaseId: selectedappointment.purchaseId,
              purchaseStepId: selectedappointment.id,
              subject: values.subject ?? "",
              message: values.message,
            };
            var res = await postData(
              `admin/service-purchase/${selectedappointment.appointment.serviceId}/step/${selectedappointment.appointmentId}/reply`,
              temp
            );
            if (res.status == 201) {
              setReply(false);
              Modal.success({ title: "Reply sent successfully." });
              form.resetFields();
              await getupcoming(1, true);
            } else {
              Modal.error({ title: res });
            }
            setisLoadingUpcoming(false);
          }}
        >
          <Form.Item label="Subject" name="subject">
            <Input />
          </Form.Item>
          <Form.Item
            rules={[{ required: true }]}
            label="Message"
            name="message"
          >
            <Input.TextArea />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              loading={isLoadingUpcoming}
            >
              Submit
            </Button>
            <Button
              type="default"
              onClick={() => {
                setReply(false);
                setselectedappointment({});
                form.resetFields();
              }}
              style={{ marginLeft: "10px" }}
            >
              Cancel
            </Button>
          </Form.Item>
        </Form>
      </Modal> */}
    </>
  );
}

export default Translators;
