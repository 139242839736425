import { React, useState, useRef, useEffect } from "react";
import "./styles.scss";
import { Form, Table, Button, Space, Input, Modal, Select } from "antd";
import {
  SearchOutlined,
  ExclamationCircleFilled,
  PlusOutlined,
  ExclamationCircleOutlined,
} from "@ant-design/icons";

import { useMobile } from "../../hooks";
import "react-accessible-accordion/dist/fancy-example.css";
import Highlighter from "react-highlight-words";
import { fetchData, postData, putData, deleteData } from "../../endpoints";

function Privilege() {
  const [modal, contextHolder] = Modal.useModal();
  const { Option } = Select;
  const isMobile = useMobile();
  const searchInput = useRef(null);
  const { confirm } = Modal;
  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [titles, setTitles] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [newTitle, setNewTitle] = useState({});
  const [tempPrivilege, setTempPrivilege] = useState({});

  useEffect(() => {
    getPrivileges();
  }, []);

  async function getPrivileges(page = 1, reload = false) {
    setIsLoading(true);
    var temp = titles;
    if (reload) {
      temp = [];
    }
    if (titles.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/privilege?perPage=15&deletedAt=false&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setTitles(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data])
          );
        } else {
          setTitles(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setIsLoading(false);
  }
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setIsLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setTitles([]);
      await getPrivileges(1);
    } else {
      let search = "";
      if (dataIndex == "title") search = "title";
      if (dataIndex == "description") search = "description";
      if (dataIndex == "slug") search = "slug";
      if (dataIndex == "service") search = "service";

      var res = await fetchData(
        `admin/privilege/?deletedAt=false&perPage=2000&page=1&${search}=` +
          selectedKeys[0]
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status == 200) {
        setTitles(res.data.data);
      }
    }
    setIsLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setTitles([]);
    await getPrivileges(1, true);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: isMobile ? "40%" : "20%",
      ...getColumnSearchProps("title"),
    },

    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      ...getColumnSearchProps("slug"),
    },
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      ...getColumnSearchProps("service"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
    },

    {
      title: "",
      dataIndex: "",
      key: "",
      render: (u) => (
        <p
          style={{ color: "#BC2323" }}
          onClick={() => {
            // var res = await deleteData(`admin/privilege/${u.id}`);

            // console.log(u.id)
            modal.confirm({
              title: "Are you sure you want to delete this privilege?",
              icon: <ExclamationCircleOutlined />,
              content: "",
              okText: "Confirm",
              cancelText: "Cancel",
              onOk: async () => {
                var res = await deleteData(`admin/privilege/${u.id}`);
                if (res.status == 200) {
                  Modal.success({
                    content: "Privilege Deleted Successfully",
                  });
                }
                // setTitles([])
                getPrivileges(1, true);
              },
            });
          }}
        >
          Delete
        </p>
      ),
    },
  ];
  return (
    <div
      style={{ backgroundColor: "white", minHeight: "100vh" }}
      className="d-flex flex-column w-100"
      dir="ltr"
    >
      {contextHolder}
      <div className={`${isMobile ? "col-11 d-flex flex-column mx-1 " : ""}`}>
        <div
          className={`${
            isMobile
              ? "document-category d-flex flex-column align-items-center justify-content-center py-1 px-1 my-1 gap-4"
              : "document-category d-flex flex-column align-items-center justify-content-center py-5 px-5 my-5 gap-4"
          }`}
        >
          <h1 style={{ fontSize: "24px", fontWeight: "700" }}>Privileges</h1>
          <Table
            scroll={{ x: "auto" }}
            loading={isLoading}
            columns={columns}
            // size={15}
            dataSource={titles}
            size="small"
            footer={() => {
              return (
                <div
                  onClick={() => {
                    // setOpenaddevent(true);
                    setIsOpen(true);
                  }}
                  className="d-flex fle-row gap-2 px-4 align-items-center"
                  style={{ cursor: "pointer" }}
                >
                  <PlusOutlined
                    style={{
                      color: "rgba(46, 108, 223, 1)",
                    }}
                  />
                  <h6
                    style={{
                      color: "rgba(46, 108, 223, 1)",
                      marginBottom: "0",
                    }}
                  >
                    Add Privilege
                  </h6>
                </div>
              );
            }}
            style={{ width: "100%" }}
            pagination={{
              onChange: async (page) => {
                await getPrivileges(page);
              },
              pageSize: 15,
            }}
          />
        </div>
      </div>
      <Modal
        style={{ top: "15vh" }}
        open={isOpen}
        confirmLoading={isLoading}
        okText={"Add"}
        icon={false}
        width={"700px"}
        onOk={async () => {
          setIsLoading(true);
          if (
            tempPrivilege.description &&
            tempPrivilege.title &&
            tempPrivilege.slug
          ) {
            var res = await postData("admin/privilege", {
              ...tempPrivilege,
              service: "user",
            });
            if (res.status == 201) {
              setIsOpen(false);
              setTempPrivilege({});

              getPrivileges(1, true);
            } else {
              Modal.error({
                title: res,
                // content: "some messages...some messages...",
              });
            }
          } else {
            Modal.error({
              title: "Please fill in the required parameters.",
              // content: "some messages...some messages...",
            });
          }
          setIsLoading(false);
        }}
        onCancel={() => {
          // setIsEdit(false);
          // setNewTitle({});
          setIsOpen(false);
          setTempPrivilege({});
        }}
      >
        <div
          style={{ textAlign: "center", fontWeight: "bold", fontSize: "18px" }}
        >
          Add Privilege
        </div>
        <hr />
        <p>
          <div>
            <strong style={{ color: "red" }}>*</strong>Title:
          </div>
          <Input
            placeholder="Enter title"
            onChange={(e) => {
              setTempPrivilege({ ...tempPrivilege, title: e.target.value });
            }}
            // defaultValue={tempPrivilege.title}
            value={tempPrivilege.title}
          />
        </p>

        <p>
          <div>
            <strong style={{ color: "red" }}>*</strong>Slug:
          </div>
          <Input
            placeholder="Enter Slug"
            onChange={(e) => {
              setTempPrivilege({ ...tempPrivilege, slug: e.target.value });
            }}
            value={tempPrivilege.slug}
          />
        </p>
        {/* <p>
          <div>
            <strong style={{ color: "red" }}>*</strong>Service:
          </div>
          <Input
            placeholder="Enter Service"
            onChange={(e) => {
              setTempPrivilege({ ...tempPrivilege, service: e.target.value });
            }}
          />
        </p> */}

        <p>
          <div>
            <strong style={{ color: "red" }}>*</strong>Description:
          </div>
          <Input
            placeholder="Enter Description"
            onChange={(e) => {
              setTempPrivilege({
                ...tempPrivilege,
                description: e.target.value,
              });
            }}
            value={tempPrivilege.description}
          />
        </p>
      </Modal>
    </div>
  );
}
export default Privilege;
