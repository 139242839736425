import { React, useState, useEffect } from "react";
import "./styles.scss";
import {
  SearchOutlined,
  PlusOutlined,
  ExclamationCircleFilled,
  AppstoreFilled,
} from "@ant-design/icons";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import dayjs from "dayjs";
import {
  Button,
  Spin,
  Form,
  Input,
  Space,
  Table,
  Tabs,
  Tag,
  DatePicker,
  Select,
  Dropdown,
  Tooltip,
} from "antd";
import Highlighter from "react-highlight-words";
import { deleteData, fetchData, postData, putData } from "../../endpoints";
import { useAssets, useMobile } from "../../hooks/index";
import PatientPage from "../patientPage";
import AddPatient from "../AddPatient";
import { Modal } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import NotificationSetting from "../Setting/notification/notification";
import access from "../../assets/access";
import Cookies from "js-cookie";
import full from "../../assets/fullAccess";
const privilage = Cookies.get("privilege") ?? full;
const UserPage = ({
  user,
  setUser,
  setIsOpen,
  isOpenPatient,
  setIsOpenPatient,
  patients,
  getPatient,
  patient,
  setPatient,
  loading,
  setLoading,
  isModalOpen,
  setIsModalOpen,
  searchText,
  setSearchText,
  searchedColumn,
  setSearchedColumn,
  selectedRowKeys,
  setSelectedRowKeys,
  searchInput,
  option,
  isEdit,
  setIsEdit,
  serviceTypesarray,
  isMobile,
  fetchUsers,
}) => {
  const { getFile } = useAssets();
  // const isMobile = useMobile();
  const [navMobileItem, setNavMobileItem] = useState(0);
  const navigator = useNavigate();
  const path = useLocation().pathname;
  const items = [
    privilage.indexOf(access.userManegment.showPatient) > -1 && {
      key: "1",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 0 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(0)}
        >
          Overview
        </button>
      ),
    },
    privilage.indexOf(access.userManegment.showDivice) > -1 && {
      key: "2",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 1 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(1)}
        >
          Last Login
        </button>
      ),
    },
    privilage.indexOf(access.userManegment.showProfile) > -1 && {
      key: "3",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 2 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(2)}
        >
          Account Settings
        </button>
      ),
    },
    {
      key: "4",
      label: (
        <button
          className={`menu-btn-mobile-item ${
            navMobileItem === 3 && "menu-btn-mobile-item-active"
          }`}
          onClick={() => setNavMobileItem(3)}
        >
          Notification
        </button>
      ),
    },
  ];
  return (
    <>
      {" "}
      {isOpenPatient ? (
        <PatientPage
          patient={patient}
          setPatient={setPatient}
          setIsOpen={setIsOpenPatient}
          loading={loading}
          setLoading={setLoading}
          isMobile={isMobile}
          serviceTypes={serviceTypesarray}
          getPatients={getPatient}
        />
      ) : (
        <div
          style={
            isMobile
              ? {
                  backgroundColor: "white",
                  minHeight: "100vh",
                  // paddingRight: "5%",
                  // paddingLeft: "5%",
                }
              : { backgroundColor: "white", minHeight: "100vh" }
          }
          className="d-flex flex-column w-100"
        >
          <div
            style={
              isMobile
                ? { marginTop: "70px", marginLeft: "0.5rem" }
                : { marginTop: "5px", marginLeft: "15px" }
            }
            className="d-flex flex-row gap-2 align-items-center"
          >
            <svg
              onClick={async () => {
                navigator("/users");
                setIsOpen(false);
                await fetchUsers(1, true);
              }}
              width={isMobile ? "20px" : "24px"}
              height="18"
              viewBox="0 0 24 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M22.7996 8.39557H2.65334L10.0208 1.01421C10.2528 0.78219 10.2528 0.40603 10.0208 0.174013C9.7889 -0.0580043 9.41285 -0.0580043 9.18091 0.174013L0.781469 8.57595C0.665495 8.68473 0.599609 8.83691 0.599609 8.99605C0.599609 9.15519 0.665498 9.30737 0.781469 9.41615L9.18091 17.8181C9.28965 17.9341 9.44178 18 9.60088 18C9.75997 18 9.91211 17.9341 10.0208 17.8181C10.1368 17.7093 10.2027 17.5571 10.2027 17.398C10.2027 17.2388 10.1368 17.0867 10.0208 16.9779L2.65334 9.59653H22.7996C23.131 9.59653 23.3996 9.32781 23.3996 8.99639C23.3996 8.66498 23.131 8.39625 22.7996 8.39625V8.39557Z"
                fill="#18324E"
              />
            </svg>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "500",
                paddingTop: "10px",
                color: "#18324E",
              }}
            >
              Back to users list
            </p>
          </div>
          <div
            style={
              isMobile
                ? { paddingLeft: "30px", alignItems: "center" }
                : { paddingLeft: "55px", alignItems: "center" }
            }
            className={`d-flex flex-row ${isMobile ? "gap-3" : " gap-4"}`}
          >
            <img
              src={getFile("Profile")}
              width={isMobile ? "70px" : "100px"}
              height={isMobile ? "70px" : "100px"}
              style={{ borderRadius: "45px" }}
            />
            <div
              className={`d-flex flex-column ${isMobile && "col-6"}`}
              style={{ justifyContent: "center" }}
            >
              <span
                style={
                  isMobile
                    ? { fontWeight: "700", fontSize: "16px" }
                    : { fontWeight: "700", fontSize: "20px" }
                }
              >
                {user.name}
              </span>
              <span
                style={
                  isMobile
                    ? {
                        fontWeight: "700",
                        fontSize: "14px",
                        color: "rgba(161, 129, 58, 1)",
                        marginTop: "0.2rem",
                      }
                    : {
                        fontWeight: "700",
                        fontSize: "16px",
                        color: "rgba(161, 129, 58, 1)",
                      }
                }
              >
                {user.email}
              </span>
            </div>
            {isMobile && (
              <Dropdown menu={{ items }}>
                <button
                  className="menu-btn-mobile"
                  style={{ marginBottom: "40px" }}
                >
                  <AppstoreFilled style={{ fontSize: "20px" }} />
                </button>
              </Dropdown>
            )}
          </div>{" "}
          {isMobile && <hr />}
          <div
            style={{ width: "93%", marginTop: "-20px" }}
            className="top-tabs d-flex flex-row mx-4 "
            dir="rtl"
          >
            {!isMobile && (
              <Tabs
                size={isMobile ? "small" : "large"}
                tabBarGutter={18}
                style={{ color: "black", fontWeight: "500", width: "95%" }}
                defaultActiveKey={2}
                tabPosition="top"
                direction="rtl"
                type="card"
                onTabClick={async () => {
                  var res = await fetchData(
                    "admin/user-profile/?userId=" + user.id
                  );
                  var res2 = await fetchData(
                    "admin/device/?deletedAt=false&userId[]=" + user.id
                  );
                  if (res2.status == 200 && res.status == 200) {
                    setUser({
                      ...user,
                      profile: res.data.data[0] ?? {},
                      login: res2.data.data,
                    });
                  } else if (res.status == 200 && res2.status !== 200) {
                    setUser({ ...user, profile: res.data.data[0] ?? {} });
                  } else if (res.status !== 200 && res2.status == 200) {
                    setUser({ ...user, login: res2.data.data });
                  }
                }}
                items={[
                  {
                    label: `Notifications`,
                    key: 3,
                    children: NotificationSetting(user),
                  },
                  privilage.indexOf(access.userManegment.showProfile) > -1 && {
                    label: `Account Settings`,
                    key: 0,
                    children: AccountSettings(
                      user,
                      setUser,
                      option,
                      isEdit,
                      setIsEdit,
                      isMobile
                    ),
                  },
                  privilage.indexOf(access.userManegment.showDivice) > -1 && {
                    label: `Last Login`,
                    key: 1,
                    children: LogLogin(user, setUser, loading, setLoading),
                  },
                  {
                    label: `Overview`,
                    key: 2,
                    children: Overview(
                      user,
                      patients,
                      getPatient,
                      loading,
                      setLoading,
                      setIsOpenPatient,
                      setPatient,
                      setIsModalOpen,
                      searchText,
                      setSearchText,
                      searchedColumn,
                      setSearchedColumn,
                      selectedRowKeys,
                      setSelectedRowKeys,
                      searchInput,
                      serviceTypesarray,
                      navigator,
                      path,
                      isMobile
                    ),
                  },
                ]}
              />
            )}
          </div>
          {isMobile && (
            <div>
              {navMobileItem === 0 &&
                Overview(
                  user,
                  patients,
                  getPatient,
                  loading,
                  setLoading,
                  setIsOpenPatient,
                  setPatient,
                  setIsModalOpen,
                  searchText,
                  setSearchText,
                  searchedColumn,
                  setSearchedColumn,
                  selectedRowKeys,
                  setSelectedRowKeys,
                  searchInput,
                  serviceTypesarray,
                  navigator,
                  path
                )}
              {navMobileItem === 1 &&
                LogLogin(user, setUser, loading, setLoading, isMobile)}
              {navMobileItem === 2 &&
                AccountSettings(
                  user,
                  setUser,
                  option,
                  isEdit,
                  setIsEdit,
                  isMobile
                )}
              {navMobileItem === 3 && NotificationSetting(user)}
            </div>
          )}
          <Modal
            open={isModalOpen}
            footer={(null, null)}
            width={isMobile ? "90vw" : "55vw"}
            style={isMobile ? {} : { top: "5vh" }}
            destroyOnClose={true}
            onCancel={() => {
              setIsModalOpen(false);
            }}
          >
            <AddPatient
              isOpen={isModalOpen}
              setIsModalClose={setIsModalOpen}
              p={patient}
              userId={user.id}
              getPatients={getPatient}
              serviceTypes={serviceTypesarray}
            />
          </Modal>
        </div>
      )}
    </>
  );
};

const Overview = (
  user,
  patients,
  getPatient,
  loading,
  setLoading,
  setIsOpenPatient,
  setPatient,
  setIsModalOpen,
  searchText,
  setSearchText,
  searchedColumn,
  setSearchedColumn,
  selectedRowKeys,
  setSelectedRowKeys,
  searchInput,
  serviceTypesarray,
  navigator,
  path,
  isMobile
) => {
  const { confirm } = Modal;

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    const savedState = sessionStorage.getItem("modalState");
    if (savedState) {
      setModalData(JSON.parse(savedState));
      setIsModalVisible(JSON.parse(savedState).showModal);
      sessionStorage.removeItem("modalState");
    }
  }, []);
  // const isMobile = useMobile();
  const showDeleteConfirm = async (arr) => {
    confirm({
      title: "Are you sure you want to disable this patient?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        removeGrupe(arr);
      },
      onCancel() {},
    });
  };

  const removePatient = async (id) => {
    var res = await deleteData(`admin/patient/${id}`);
    if (res.status === 200) {
      return "ok";
    } else {
      return res;
    }
  };
  const removeGrupe = async (listOfPatients) => {
    let isOk = [];
    setLoading(true);
    await listOfPatients.map(async (e, i) => {
      var res = await removePatient(e.id);
      if (!res == "ok") {
        isOk[i] = false;
        Modal.error({ title: "There was a problem, please try again." });
      } else {
        isOk[i] = true;
      }
    });
    if (
      isOk.filter((a) => {
        return a == false;
      }).length <= 0
    ) {
      await getPatient(user);
      setSelectedRowKeys([]);
      Modal.success({ title: "Patients have been successfully deleted." });
    }
    setLoading(false);
  };
  const verifyPatient = async (id) => {
    var res = await putData(`admin/patient/${id}/verify`);
    if (res.status === 200) {
      return "ok";
    } else {
      return res;
    }
  };

  const verifyGrupe = async (listOfPatients) => {
    setLoading(true);
    let isOk = [];
    await listOfPatients.map(async (e, i) => {
      var res = await verifyPatient(e.id);
      if (!res == "ok") {
        isOk[i] = false;
        Modal.error({ title: "There was a problem, please try again." });
      } else {
        isOk[i] = true;
      }
    });
    if (
      isOk.filter((a) => {
        return a == false;
      }).length <= 0
    ) {
      await getPatient(user);
      setSelectedRowKeys([]);
      Modal.success({ title: "Patient has been successfully verified." });
    }
    setLoading(false);
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = async (clearFilters, confirm, dataIndex) => {
    clearFilters();
    setSearchText("");
    handleSearch([""], confirm, dataIndex);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={async () =>
              clearFilters &&
              (await handleReset(clearFilters, confirm, dataIndex))
            }
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) =>
      searchedColumn === dataIndex ? (
        <Highlighter
          highlightStyle={{
            backgroundColor: "#ffc069",
            padding: 0,
          }}
          searchWords={[searchText]}
          autoEscape
          textToHighlight={text ? text.toString() : ""}
        />
      ) : (
        text
      ),
  });

  function isTher(value, record) {
    let is = false;
    record.serviceTypes.forEach((element) => {
      if (element.includes(value)) {
        is = true;
      }
    });
    return is;
  }
  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      width: "20%",
      onContextMenu: (record) => {
        window.open("/patients/" + record.id, "_blank");
      },
      onCell: (record) => {
        return {
          onClick: () => {
            if (privilage.indexOf(access.userManegment.indexPatient) > -1) {
              setPatient(record);
              navigator("/patients/" + record.id, {
                state: { previousPath: path },
              });
              var link = document.createElement("a");
              link.href = "/patients/" + record.id;
              link.click();
              // setIsOpenPatient(true);
            }
          },
        };
      },
      ...getColumnSearchProps("name"),
      render: (name, record) => (
        <h2
          onContextMenu={() => {
            window.open("/patients/" + record.id, "_blank");
          }}
          className="name-table"
        >
          {name}
        </h2>
      ),
    },
    {
      title: "MRN",
      dataIndex: "id",
      key: "id",
      render: (id, record) =>
        record.verifiedAt ? (
          <span>{id}</span>
        ) : privilage.indexOf(access.userManegment.verifyPatient) > -1 ? (
          <span
            onClick={async () => {
              await verifyGrupe([record]);
            }}
            style={{ color: "rgba(46, 108, 223, 1)", cursor: "pointer" }}
          >
            Verify
          </span>
        ) : (
          <span>not verified yet</span>
        ),
      width: "15%",
    },
    {
      title: "Service",
      dataIndex: "serviceTypes",
      key: "serviceTypes",
      width: "25%",
      responsive: ["md"],
      render: (serviceTypes, record) => (
        <div className="d-flex flex-column gap-2">
          {serviceTypes.map((s, i) => {
            let color = ["geekblue", "purple", "magenta"];

            return (
              <Tag color={color[i % 3]} key={s}>
                {s.serviceType.title}
              </Tag>
            );
          })}
        </div>
      ),
    },
    {
      title: "Relationship",
      dataIndex: "relationship",
      key: "relationship",
      width: "10%",
      responsive: ["md"],
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      render: (u) =>
        privilage.indexOf(access.userManegment.disablePatient) > -1 && (
          <div
            style={{
              marginRight: "15px",
              alignItems: "center",
              justifyContent: "center",
            }}
            className=" d-flex flex-row gap-4"
          >
            <a
              onClick={async (e) => {
                e.stopPropagation();
                await showDeleteConfirm([u]);
              }}
              style={{ color: "#BC2323" }}
            >
              Disable
            </a>
          </div>
        ),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
    getCheckboxProps: (record) => ({
      disabled: record.name === "Disabled User",
      name: record.name,
    }),
  };
  const data = patients.map((p, i) => {
    return {
      ...p,
      key: i,
      name: p.firstName + " " + p.lastName,
      createdAt: p.createdAt.substr(0, 10),
    };
  });

  return (
    <div className="d-flex flex-column" dir="ltr">
      <div className="table-box w-100">
        <div className="d-flex flex-row align-items-center py-4 w-100">
          <span style={{ fontSize: "20px", fontWeight: "700", width: "15%" }}>
            Patient list
          </span>
          <div
            className="d-flex flex-row gap-3"
            style={{
              justifyContent: "flex-end",
              width: "85%",
              fontWeight: "bold",
            }}
          >
            {selectedRowKeys.length > 0 && (
              <>
                <span
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setSelectedRowKeys([]);
                  }}
                >
                  Cancel
                </span>
                {privilage.indexOf(access.userManegment.verifyPatient) > -1 && (
                  <span
                    onClick={async () => {
                      verifyGrupe(
                        data.filter((u) => {
                          let is;
                          for (let i = 0; i < selectedRowKeys.length; i++) {
                            if (u.key === selectedRowKeys[i]) {
                              is = true;
                              break;
                            } else {
                              is = false;
                            }
                          }
                          return is;
                        })
                      );
                    }}
                    style={{
                      color: "rgba(46, 108, 223, 1)",
                      cursor: "pointer",
                    }}
                  >
                    Verify
                  </span>
                )}
                {privilage.indexOf(access.userManegment.disablePatient) >
                  -1 && (
                  <span
                    onClick={async () => {
                      showDeleteConfirm(
                        data.filter((u) => {
                          let is;
                          for (let i = 0; i < selectedRowKeys.length; i++) {
                            if (u.key === selectedRowKeys[i]) {
                              is = true;
                              break;
                            } else {
                              is = false;
                            }
                          }
                          return is;
                        })
                      );
                    }}
                    style={{ color: "#BC2323", cursor: "pointer" }}
                  >
                    Disable
                  </span>
                )}
              </>
            )}
          </div>
        </div>
        <Table
          loading={loading}
          rowSelection={{
            type: "checkbox",
            ...rowSelection,
          }}
          pagination={{
            pageSize: 15,
          }}
          columns={columns}
          dataSource={data}
          size="small"
          // onChange={filterServiceType}
          style={{ width: "100%" }}
          footer={() => {
            if (privilage.indexOf(access.userManegment.addPatient) > -1)
              return (
                <div
                  onClick={() => {
                    setIsModalOpen(true);
                  }}
                  style={{ cursor: "pointer" }}
                  className="d-flex fle-row gap-2 px-4 align-items-center"
                >
                  <PlusOutlined
                    style={{
                      color: "rgba(46, 108, 223, 1)",
                    }}
                  />
                  <h6
                    style={{
                      color: "rgba(46, 108, 223, 1)",
                      marginBottom: "0",
                    }}
                  >
                    Add Patient
                  </h6>
                </div>
              );
          }}
        />
      </div>
      <Modal
        visible={isModalVisible}
        onOk={() => {
          setIsModalVisible(false);
        }}
        cancelButtonProps={{ style: { display: "none" } }}
        closeIcon={null}
      >
        <p style={{ fontWeight: "700" }}>
          {modalData?.message || "Something went wrong!"}
        </p>
      </Modal>
    </div>
  );
};
const AccountSettings = (
  user,
  setUser,
  option,
  isEdit,
  setIsEdit,
  isMobile
) => {
  const saveProfile = async () => {
    if (user.profile.id) {
      var res = await putData(
        `admin/user-profile/${user.profile.id}`,
        user.profile
      );
      if (res.status == 200) {
        Modal.success({ title: res.data.message });
      } else {
        Modal.error({ title: res });
      }
    } else {
      var res = await postData(`admin/user-profile`, {
        ...user.profile,
        userId: user.id,
      });
      if (res.status == 201) {
        Modal.success({ title: res.data.message });
      } else {
        Modal.error({ title: res });
      }
    }
  };
  function EmailValidate(email) {
    return email
      .toLowerCase()
      .match(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/);
  }
  const saveAccount = async () => {
    if (user.email && !EmailValidate(user.email)) {
      Modal.error({ title: "Please enter a valid email!" });
      return false;
    }
    if (user.phoneNumber && !isValidPhoneNumber(user.phoneNumber)) {
      Modal.error({ title: "Please enter a valid phone number!" });
      return false;
    }
    var temp = user;
    if (user.type == "super_user") {
      delete temp.type;
    }
    var res = await putData(`admin/user/${user.id}`, temp);
    if (res.status == 200) {
      setUser({
        ...user,
        name: res.data.data.firstName + " " + res.data.data.lastName,
      });
      return true;
    } else {
      Modal.error({ title: res });
      return false;
    }
  };
  return (
    <div
      style={{ marginLeft: "-40px" }}
      className={`${
        isMobile ? "py-3 px-5" : "d-flex flex-row gap-4 py-3 px-5"
      }`}
      dir="ltr"
    >
      <div
        className={`${
          isMobile
            ? "d-flex flex-column gap-3 col-12 px-3"
            : "d-flex flex-column gap-3 col-6 px-3"
        }`}
      >
        <div className="d-flex flex-row w-100">
          <h2
            style={
              isMobile
                ? { fontSize: "16px", fontWeight: "700" }
                : { fontSize: "20px", fontWeight: "700" }
            }
            className="col-10"
          >
            Account Information
          </h2>
        </div>
        <Form
          // form={myForm}
          name="accountInfo"
          layout="vertical"
          style={{ maxWidth: "480px" }}
          initialValues={{
            email: user.email,
            phoneNumber: user.phoneNumber,
          }}
        >
          <Form.Item
            name={"email"}
            label="Email"
            rules={[
              { required: true, message: "Please enter an email address!" },
            ]}
          >
            <Input
              disabled={!isEdit}
              onChange={(e) => {
                setUser({ ...user, email: e.target.value });
              }}
            />
          </Form.Item>
          <div
            style={{ marginTop: "-10px" }}
            className={"d-flex flex-column w-100 gap-1"}
          >
            <span
              style={{ fontSize: "14px", fontWeight: "500" }}
              className="col-3"
            >
              Phone Number
            </span>

            {isEdit ? (
              <PhoneInput
                // disabled

                className="col-12 my-1 phon-input"
                placeholder="Enter phone number"
                value={user.phoneNumber}
                defaultCountry="CA"
                error={
                  user.phoneNumber
                    ? isValidPhoneNumber(user.phoneNumber)
                      ? undefined
                      : "Invalid phone number"
                    : "Phone number required"
                }
                onChange={(v) => {
                  setUser({ ...user, phoneNumber: v });
                }}
              />
            ) : (
              <Form.Item>
                <Input value={user.phoneNumber} disabled={!isEdit} />
              </Form.Item>
            )}
          </div>
        </Form>
      </div>
      <div
        className={`${
          isMobile
            ? "d-flex flex-column gap-3 col-12 py-3 px-3"
            : "d-flex flex-column gap-3 col-6 px-3"
        }`}
      >
        <div className="d-flex flex-row w-100">
          <h2
            style={
              isMobile
                ? { fontSize: "16px", fontWeight: "700" }
                : { fontSize: "20px", fontWeight: "700" }
            }
            className="col-10"
          >
            Profile Information
          </h2>
          {!isMobile &&
            privilage.indexOf(access.userManegment.editProfile) > -1 &&
            privilage.indexOf(access.userManegment.editUser) > -1 && (
              <Button
                type="primary"
                className="col-2"
                onClick={async () => {
                  if (isEdit) {
                    var res = await saveAccount();
                    if (res) {
                      await saveProfile();
                      setIsEdit(!isEdit);
                    }
                  } else {
                    setIsEdit(!isEdit);
                  }
                }}
              >
                {isEdit ? "Save" : "Edit"}
              </Button>
            )}
        </div>
        {option.Sex && user.profile ? (
          <Form
            name="myForm"
            layout="vertical"
            style={{ maxWidth: "480px" }}
            initialValues={{
              firstName: user.firstName,
              middleName: user.profile.middleName,
              lastName: user.lastName,
              preferredName: user.profile.preferredName,
              prefix: user.profile.prefix,
              pronouns: user.profile.pronouns,
              career: user.profile.career,
              sex: user.profile.sex,

              gender: user.profile.gender,
              country: user.profile.address ? user.profile.address.country : "",
              province: user.profile.address
                ? user.profile.address.province
                : "",
              city: user.profile.address ? user.profile.address.city : "",
              addressLine1: user.profile.address
                ? user.profile.address.addressLine1
                : "",
              addressLine2: user.profile.address
                ? user.profile.address.addressLine2
                : "",
              postalCode: user.profile.address
                ? user.profile.address.postalCode
                : "",
            }}
          >
            <div className="d-flex flex-row gap-5">
              <Form.Item
                name={"firstName"}
                label="First Name"
                rules={[
                  { required: true, message: "Please fill out the firstname!" },
                ]}
              >
                <Input
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      firstName: e.target.value,
                    });
                  }}
                />
              </Form.Item>
              <Form.Item name={"middleName"} label="Middle Name">
                <Input
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, middleName: e.target.value },
                    });
                  }}
                />
              </Form.Item>
            </div>
            <Form.Item
              name={"lastName"}
              label="Last Name"
              rules={[
                { required: true, message: "Please fill out the lastname!" },
              ]}
            >
              <Input
                disabled={!isEdit}
                onChange={(e) => {
                  setUser({
                    ...user,
                    lastName: e.target.value,
                  });
                }}
              />
            </Form.Item>
            <Form.Item name={"preferredName"} label="Preferred Name">
              <Input
                disabled={!isEdit}
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: { ...user.profile, preferredName: e.target.value },
                  });
                }}
              />
            </Form.Item>
            <Form.Item name={"birthday"} label="Date Of Birth">
              <DatePicker
                className="w-100"
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: { ...user.profile, birthday: e.toString() },
                  });
                }}
                defaultValue={
                  user.profile.birthday
                    ? dayjs(user.profile.birthday.substr(0, 10), "YYYY-MM-DD")
                    : null
                }
                disabled={!isEdit}
              />
            </Form.Item>
            <div className="d-flex flex-row gap-5">
              {" "}
              <Form.Item name={"sex"} label="Sex" className="col-5">
                <Select
                  className="w-100"
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, sex: e },
                    });
                  }}
                >
                  {option.Sex.map((s) => {
                    return <Select.Option value={s}>{s}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name={"gender"}
                label="Gender Identity"
                className="col-5"
              >
                <Select
                  className="w-100"
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, gender: e },
                    });
                  }}
                >
                  {option.Gender.map((s) => {
                    return <Select.Option value={s}>{s}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
            <div className="d-flex flex-row gap-5">
              <Form.Item name={"pronouns"} label="Pronouns" className="col-5">
                <Select
                  className="w-100"
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, pronouns: e },
                    });
                  }}
                >
                  {option.Pronouns.map((s) => {
                    return <Select.Option value={s}>{s}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item name={"prefix"} label="Prefix" className="col-5">
                <Select
                  className="w-100"
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: { ...user.profile, prefix: e },
                    });
                  }}
                >
                  {option.Prefix.map((s) => {
                    return <Select.Option value={s}>{s}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
            <Form.Item
              name={"career"}
              label="Career Position"
              className="col-12"
            >
              <Select
                className="w-100"
                disabled={!isEdit}
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: { ...user.profile, career: e },
                  });
                }}
              >
                {option.CareerPosition.map((s) => {
                  return <Select.Option value={s}>{s}</Select.Option>;
                })}
              </Select>
            </Form.Item>
            <div className="d-flex flex-row gap-5">
              <Form.Item name={"country"} label="Country" className="col-5">
                <Select
                  className="w-100"
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: {
                        ...user.profile,
                        address: { ...user.profile.address, country: e },
                      },
                    });
                  }}
                >
                  {option.Country.map((s) => {
                    return <Select.Option value={s}>{s}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
              <Form.Item name={"province"} label="Province" className="col-5">
                <Select
                  className="w-100"
                  disabled={!isEdit}
                  onChange={(e) => {
                    setUser({
                      ...user,
                      profile: {
                        ...user.profile,
                        address: { ...user.profile.address, province: e },
                      },
                    });
                  }}
                >
                  {option.Province.map((s) => {
                    return <Select.Option value={s}>{s}</Select.Option>;
                  })}
                </Select>
              </Form.Item>
            </div>
            <Form.Item name={"city"} label="City" className="col-12">
              <Select
                className="w-100"
                disabled={!isEdit}
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      address: { ...user.profile.address, city: e },
                    },
                  });
                }}
              >
                {(user.profile
                  ? user.profile.address
                    ? user.profile.address.province
                      ? Object.values(option.City)[0][
                          user.profile.address.province
                        ]
                      : []
                    : []
                  : []
                ).map((s) => {
                  return <Select.Option value={s}>{s}</Select.Option>;
                })}
              </Select>
            </Form.Item>
            <Form.Item name={"addressLine1"} label="Address Line 1">
              <Input
                disabled={!isEdit}
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      address: {
                        ...user.profile.address,
                        addressLine1: e.target.value,
                      },
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item name={"addressLine2"} label="Address Line 2">
              <Input
                disabled={!isEdit}
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      address: {
                        ...user.profile.address,
                        addressLine2: e.target.value,
                      },
                    },
                  });
                }}
              />
            </Form.Item>
            <Form.Item name={"postalCode"} label="Postal Code">
              <Input
                disabled={!isEdit}
                onChange={(e) => {
                  setUser({
                    ...user,
                    profile: {
                      ...user.profile,
                      address: {
                        ...user.profile.address,
                        postalCode: e.target.value,
                      },
                    },
                  });
                }}
              />
            </Form.Item>
          </Form>
        ) : (
          <Spin size="large" />
        )}
        {isMobile && (
          <Button
            type="primary"
            className="col-4"
            onClick={async () => {
              setIsEdit(!isEdit);
              if (isEdit) {
                var res = await saveAccount();
                if (res) {
                  await saveProfile();
                }
              }
            }}
          >
            {isEdit ? "Save" : "Edit"}
          </Button>
        )}
      </div>
    </div>
  );
};
const LogLogin = (user, setUser, loading, setLoading, isMobile) => {
  const terminate = async (u) => {
    setLoading(true);
    var res = await deleteData("admin/device/" + u.id);
    if (res.status == 200) {
      Modal.success({ title: res.data.message });
      await getDevice();
    } else {
      Modal.error({ title: res });
      await getDevice();
    }
    setLoading(false);
  };
  const getDevice = async () => {
    setLoading(true);
    var res2 = await fetchData(
      "admin/device/?deletedAt=false&userId[]=" + user.id
    );
    if (res2.status == 200) {
      setUser({
        ...user,
        login: res2.data.data,
      });
    }
    setLoading(false);
  };
  const columns = [
    {
      title: (
        <div style={isMobile ? { fontSize: "12px" } : { fontSize: "16px" }}>
          Model
        </div>
      ),
      dataIndex: "model",
      key: "model",
      width: "45%",
      render(u) {
        return <Tooltip title={u}>{u.substr(0, 45) + "..."}</Tooltip>;
      },
    },

    {
      title: (
        <div
          style={
            isMobile
              ? { fontSize: "12px", whiteSpace: "nowrap" }
              : { fontSize: "16px" }
          }
        >
          Created At
        </div>
      ),
      dataIndex: "createdAt",
      key: "createdAt",
      width: "25%",
      render(u) {
        return new Date(u).toDateString();
      },
    },
    {
      title: (
        <div
          style={
            isMobile
              ? { fontSize: "12px", whiteSpace: "nowrap" }
              : { fontSize: "16px" }
          }
        >
          Last Login
        </div>
      ),
      dataIndex: "lastLoginAt",
      key: "lastLoginAt",
      width: "30%",
      render(u) {
        return new Date(u).toUTCString();
      },
    },
    {
      title: "",
      dataIndex: "",
      key: "x",
      width: "15%",
      render: (u) => (
        <div
          style={{
            marginRight: "15px",
            alignItems: "center",
            justifyContent: "center",
          }}
          className=" d-flex flex-row gap-4"
        >
          <a
            onClick={async (e) => {
              Modal.confirm({
                title: "Are you sure to terminate this session? ",
                icon: <ExclamationCircleFilled />,
                okText: "Yes",
                okType: "danger",
                cancelText: "No",
                onOk() {
                  terminate(u);
                },
              });
            }}
            style={{ color: "#BC2323" }}
          >
            Terminate
          </a>
        </div>
      ),
    },
  ];
  // const [loading, setLoading] = useState(false);
  return (
    <div className="otp d-flex align-items-center justify-content-center w-100 py-4">
      <div className="w-100" dir="ltr">
        <Table
          columns={columns}
          dataSource={user.login}
          size="small"
          style={{ width: "100%" }}
          loading={loading}
          pagination={{
            pageSize: 15,
          }}
        />
      </div>
    </div>
  );
};
export default UserPage;
