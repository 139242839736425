import { Modal, Table, Input, Space, Button, Form } from "antd";
import { useState, useEffect, useRef } from "react";
import {
  ExclamationCircleFilled,
  PlusOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import Highlighter from "react-highlight-words";
import { fetchData, postData, deleteData, putData } from "../../endpoints";
import { useMobile } from "../../hooks";

const Roles = (user) => {
  const [admins, setAdmins] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");
  const [editOpen, setEditOpen] = useState(false);
  const [selected, setSelected] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const searchInput = useRef(null);
  const isMobile = useMobile();
  const getRoles = async (page = 1, reload = false) => {
    setLoading(true);
    var temp = admins;
    if (reload) {
      temp = [];
    }
    if (admins.length < (page - 1) * 15 + 2 || reload) {
      var res = await fetchData(
        "admin/role/?deletedAt=false&perPage=15&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 15) {
          setAdmins(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data])
          );
        } else {
          setAdmins(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }

    setLoading(false);
  };
  useEffect(() => {
    getRoles();
  }, []);
  const { confirm } = Modal;

  const deleteRole = async (role) => {
    setLoading(true);
    var res = await deleteData("admin/role/" + role.id);
    if (res.status != 200) {
      Modal.error({ title: res });
    } else {
      Modal.success({ title: "Role have been successfully deleted." });
      await getRoles(1, true);
    }
    setLoading(false);
  };

  const showDeleteConfirm = async (role) => {
    confirm({
      title: "Are you sure delete this role?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteRole(role);
      },
      onCancel() {},
    });
  };

  const handleSearch = (selectedKeys, confirm, dataIndex) => {
    confirm();
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={async () => {
              clearFilters && handleReset(clearFilters);
              handleSearch([""], confirm, dataIndex);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex] &&
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      title: "Id",
      dataIndex: "id",
      key: "id",
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "20%",
      ...getColumnSearchProps("title"),
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      ...getColumnSearchProps("description"),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (u) => (
        <p
          onClick={() => {
            setSelected(u);
            setEditOpen(true);
          }}
          style={{ textAlign: "center", cursor: "pointer" }}
        >
          Edit
        </p>
      ),
    },
    {
      title: "",
      dataIndex: "",
      key: "",
      render: (u) => (
        <p
          style={{ color: "#BC2323", textAlign: "center", cursor: "pointer" }}
          onClick={() => {
            showDeleteConfirm(u);
          }}
        >
          Delete
        </p>
      ),
    },
  ];
  return (
    <div
      style={{ marginLeft: "-40px" }}
      className="d-flex flex-column gap-4 py-3 px-5"
      dir="ltr"
    >
      <h2
        style={
          isMobile
            ? { fontSize: "18px", fontWeight: "700", marginLeft: "5%" }
            : { fontSize: "24px", fontWeight: "700", marginLeft: "5%" }
        }
      >
        Roles
      </h2>
      <div>
        <Table
          loading={loading}
          columns={columns}
          size="small"
          style={{ width: "100%" }}
          footer={() => {
            return (
              <div
                onClick={() => {
                  setIsModalOpen(true);
                }}
                style={{ cursor: "pointer" }}
                className="d-flex fle-row gap-2 px-4 align-items-center"
              >
                <PlusOutlined
                  style={{
                    color: "rgba(46, 108, 223, 1)",
                  }}
                />
                <h6
                  style={{
                    color: "rgba(46, 108, 223, 1)",
                    marginBottom: "0",
                  }}
                >
                  Add New Role
                </h6>
              </div>
            );
          }}
          dataSource={admins.map((a, i) => {
            return { ...a, key: i, name: a.firstName + " " + a.lastName };
          })}
        />
      </div>
      <Modal
        open={isModalOpen}
        footer={(null, null)}
        style={{ top: "5vh" }}
        width={isMobile ? 300 : 900}
        onCancel={() => {
          setIsModalOpen(false);
        }}
        destroyOnClose
      >
        <AddRoles setIsModalOpen={setIsModalOpen} getRoles={getRoles} />
      </Modal>
      <Modal
        open={editOpen}
        footer={(null, null)}
        style={{ overflowX: "scroll" }}
        width={isMobile ? 300 : 900}
        onCancel={() => {
          setEditOpen(false);
        }}
        destroyOnClose
      >
        <div style={{ display: "flex", justifyContent: "center" }}>
          <EditRoles
            setIsModalOpen={setEditOpen}
            getRoles={getRoles}
            selected={selected}
            isModalOpen={editOpen}
          />
        </div>
      </Modal>
    </div>
  );
};
const AddRoles = ({ setIsModalOpen, getRoles }) => {
  const [role, setRole] = useState({});
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const searchInput = useRef(null);
  const [searchedColumn, setSearchedColumn] = useState("");
  const [searchText, setSearchText] = useState("");

  const [myForm] = Form.useForm();

  useEffect(() => {
    getPrivileges();
  }, []);
  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setList([]);
      await getPrivileges(1);
    } else {
      let search = "";
      if (dataIndex == "title") search = "title";
      if (dataIndex == "description") search = "description";
      if (dataIndex == "slug") search = "slug";
      if (dataIndex == "service") search = "service";

      var res = await fetchData(
        `admin/privilege/?deletedAt=false&${search}=` + selectedKeys[0]
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status == 200) {
        setList(res.data.data);
      }
    }
    setLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setList([]);
    // await fetchEvents(1, true);
    await getPrivileges(1);
  };

  async function getPrivileges(page = 1, reload = false) {
    setLoading(true);
    var temp = list;
    if (reload) {
      temp = [];
    }
    if (list.length < (page - 1) * 10 + 2 || reload) {
      var res = await fetchData(
        "admin/privilege?perPage=10&deletedAt=false&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setList(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data])
          );
        } else {
          setList(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  }
  const addRole = async (role) => {
    setLoading(true);
    var res = await postData("admin/role", {
      title: role.title,
      description: role.description,
    });
    if (res.status != 201) {
      Modal.error({ title: res });
    } else {
      setRole({ ...role, id: res.data.data.id });
      setStep(2);
    }
    setLoading(false);
  };
  const addRolePrivilege = async (role, list) => {
    setLoading(true);
    if (list.length > 0) {
      for (let index = 0; index < list.length; index++) {
        const element = list[index];
        var res = await postData(
          "admin/role/" + role.id + "/privilege/" + element
        );
        if (res.status != 201) {
          Modal.error({ title: res });
          setLoading(false);
          return;
        }
      }
      Modal.success({ title: res.data.message });
    }
    setStep(1);
    setRole({});
    setSelectedRowKeys([]);
    myForm.resetFields();
    setIsModalOpen(false);
    await getRoles(1, true);
    setLoading(false);
  };
  const isMobile = useMobile();
  const getColumnSearchPropsPre = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>

          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "30%",
      ...getColumnSearchPropsPre("title"),
    },

    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      ...getColumnSearchPropsPre("slug"),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      ...getColumnSearchPropsPre("service"),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  return (
    <div
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
      }}
    >
      {step == 1 ? (
        <>
          {" "}
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
            Create New Role
          </h2>
          <div
            style={{
              width: "95%",
              height: "1px",
              backgroundColor: "gray",
              marginBottom: "15px",
            }}
          />
          <Form
            style={
              isMobile
                ? { width: "90%", margin: "auto" }
                : { width: "40%", marginLeft: "30%" }
            }
            layout="vertical"
            initialValues={{ role: role }}
            form={myForm}
          >
            <Form.Item
              name={"title"}
              label={"Title"}
              rules={[
                { required: true, message: "Please enter the Title of role" },
              ]}
            >
              <Input
                onChange={(e) => {
                  setRole({ ...role, title: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name={"description"}
              label={"Description"}
              rules={[
                {
                  required: true,
                  message: "Please enter a description for the role.",
                },
              ]}
            >
              <Input.TextArea
                onChange={(e) => {
                  setRole({ ...role, description: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                onClick={() => addRole(role)}
              >
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
            Add privileges of role
          </h2>
          <div
            style={{
              width: "95%",
              height: "1px",
              backgroundColor: "black",
              marginBottom: "30px",
            }}
          />
          <Table
            scroll={{ x: "auto" }}
            loading={loading}
            columns={columns}
            dataSource={list.map((l) => {
              return { ...l, key: l.id };
            })}
            rowSelection={{ type: "checkbox", ...rowSelection }}
            size="small"
            style={{ width: "95%" }}
            pagination={{
              onChange: async (page) => {
                await getPrivileges(page);
              },
              pageSize: 10,
            }}
          />
          <Button
            loading={loading}
            type="primary"
            onClick={() => addRolePrivilege(role, selectedRowKeys)}
          >
            Confirm
          </Button>
        </>
      )}
    </div>
  );
};
const EditRoles = ({ setIsModalOpen, getRoles, selected, isModalOpen }) => {
  const [role, setRole] = useState(selected);
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [myForm] = Form.useForm();
  const isMobile = useMobile();
  const searchInput = useRef(null);
  const [searchText, setSearchText] = useState("");
  const [searchedColumn, setSearchedColumn] = useState("");

  const handleSearch = async (selectedKeys, confirm, dataIndex) => {
    setLoading(true);
    setSearchText(selectedKeys[0]);
    setSearchedColumn(dataIndex);
    if (selectedKeys.length === 0) {
      setList([]);
      await getPrivileges(1);
    } else {
      let search = "";
      if (dataIndex == "title") search = "title";
      if (dataIndex == "description") search = "description";
      if (dataIndex == "slug") search = "slug";
      if (dataIndex == "service") search = "service";

      var res = await fetchData(
        `admin/privilege/?deletedAt=false&${search}=` + selectedKeys[0]
      );
      res.data.data.map((tempdata, index) => {
        tempdata["key"] = index;
      });
      if (res.status == 200) {
        setList(res.data.data);
      }
    }
    setLoading(false);
  };
  const handleReset = async (clearFilters) => {
    clearFilters();
    setSearchText("");
    setList([]);
    // await fetchEvents(1, true);
    await getPrivileges(1, true);
  };
  const getColumnSearchProps = (dataIndex) => ({
    filterDropdown: ({
      setSelectedKeys,
      selectedKeys,
      confirm,
      clearFilters,
      close,
    }) => (
      <div
        style={
          isMobile
            ? { padding: 4 }
            : {
                padding: 8,
              }
        }
        onKeyDown={(e) => e.stopPropagation()}
      >
        <Input
          ref={searchInput}
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={(e) =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
          style={
            isMobile
              ? {
                  marginBottom: 2,
                  display: "block",
                  padding: 1,
                  fontSize: "10px",
                }
              : {
                  marginBottom: 8,
                  display: "block",
                }
          }
        />
        <Space>
          <Button
            type="primary"
            onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
            icon={<SearchOutlined />}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Search
          </Button>
          <Button
            onClick={() => {
              clearFilters && handleReset(clearFilters);
              handleSearch(selectedKeys, confirm, dataIndex);
            }}
            size="small"
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            Reset
          </Button>
          <Button
            type="link"
            size="small"
            onClick={() => {
              close();
            }}
            style={
              isMobile
                ? {
                    width: "auto",
                    display: "flex",
                    alignItems: "center",
                    fontSize: "10px",
                    padding: "4",
                  }
                : {
                    width: 90,
                  }
            }
          >
            close
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered) => (
      <SearchOutlined
        style={{
          color: filtered ? "#1890ff" : undefined,
        }}
      />
    ),
    onFilter: (value, record) =>
      record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (text) => text,
  });
  useEffect(() => {
    if (isModalOpen) {
      setRole(selected);
      myForm.setFieldsValue({
        title: selected.title,
        description: selected.description,
      });
    }
    getPrivileges();
  }, [isModalOpen]);
  async function getPrivileges(page = 1, reload = false) {
    setLoading(true);
    var temp = list;
    if (reload) {
      temp = [];
    }
    if (list.length < (page - 1) * 10 + 2 || reload) {
      var res = await fetchData(
        "admin/privilege?perPage=10&deletedAt=false&page=" + page
      );
      if (res.status === 200) {
        if (res.data.data.length < 10) {
          setList(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data])
          );
        } else {
          setList(
            temp
              .filter((a, i) => {
                return a.title;
              })
              .concat([...res.data.data, {}])
          );
        }
      } else {
        Modal.error({ title: res });
      }
    }
    setLoading(false);
  }
  const addRolePrivilege = async (role, list) => {
    setLoading(true);
    for (let index = 0; index < list.length; index++) {
      const element = list[index];
      var res = await postData(
        "admin/role/" + role.id + "/privilege/" + element
      );
      if (res.status != 201) {
        Modal.error({ title: res });
        setLoading(false);
        return;
      }
    }
    Modal.success({ title: "Role edited successfuly!" });
    setStep(1);
    setRole({});
    setSelectedRowKeys([]);
    myForm.resetFields();
    setIsModalOpen(false);
    await getRoles(1, true);
    setLoading(false);
  };
  const columns = [
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
      width: "30%",
      ...getColumnSearchProps("title"),
    },

    {
      title: "Slug",
      dataIndex: "slug",
      key: "slug",
      ...getColumnSearchProps("slug"),
    },
    {
      title: "Service",
      dataIndex: "service",
      key: "service",
      ...getColumnSearchProps("service"),
    },
  ];
  const rowSelection = {
    selectedRowKeys,
    onChange: (newSelectedRowKeys) => {
      setSelectedRowKeys(newSelectedRowKeys);
    },
  };
  const editRole = async (role) => {
    if (!role.title || !role.description) {
      Modal.error({ title: "please fill all information" });
      return;
    }
    setLoading(true);

    if (role.title) {
      var res = await putData("admin/role/" + role.id, {
        title: role.title,
        description: role.description,
      });
      if (res.status != 200) {
        Modal.error({ title: res });
      } else {
        setStep(2);
      }
    } else {
      setStep(2);
    }
    setLoading(false);
  };
  const deleteRolePrivilege = async (role, privilege) => {
    setLoading(true);
    var res = await deleteData(
      "admin/role/" + role.id + "/privilege/" + privilege.id
    );
    if (res.status != 200) {
      Modal.error({ title: res });
    } else {
      setRole({
        ...role,
        privileges: role.privileges.filter((p) => {
          return p.privilegeId != privilege.id;
        }),
      });
    }
    setLoading(false);
  };

  const showDeleteConfirm = async (u) => {
    Modal.confirm({
      title: "Are you sure you want to disable this privilege?",
      icon: <ExclamationCircleFilled />,
      okText: "Yes",
      okType: "danger",
      cancelText: "No",
      onOk() {
        deleteRolePrivilege(selected, u);
      },
      onCancel() {},
    });
  };
  return (
    <div
      style={{
        textAlign: "center",
        justifyContent: "center",
        alignItems: "center",
        width: "90%",
      }}
    >
      {step == 1 ? (
        <>
          {" "}
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
            Edit Role {role.title}
          </h2>
          <div
            style={{
              width: "95%",
              height: "1px",
              backgroundColor: "gray",
              marginBottom: "15px",
            }}
          />
          <Form
            style={{ width: "80%", marginLeft: "10%" }}
            layout="vertical"
            defaultValue={{
              title: selected.title,
              description: selected.description,
            }}
            form={myForm}
          >
            <Form.Item
              name={"title"}
              label={"Title"}
              rules={[
                { required: true, message: "Please enter the Title of role" },
              ]}
            >
              <Input
                onChange={(e) => {
                  setRole({ ...role, title: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item
              name={"description"}
              label={"Description"}
              rules={[
                {
                  required: true,
                  message: "Please enter a description for the role.",
                },
              ]}
            >
              <Input.TextArea
                onChange={(e) => {
                  setRole({ ...role, description: e.target.value });
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                loading={loading}
                type="primary"
                onClick={() => editRole(role)}
              >
                Confirm
              </Button>
            </Form.Item>
          </Form>
        </>
      ) : (
        <>
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
            Edit privileges of role
          </h2>
          <div
            style={{
              width: "100%",
              height: "1px",
              backgroundColor: "black",
              marginBottom: "30px",
            }}
          />
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
            current privileges
          </h2>
          <Table
            loading={loading}
            columns={[
              ...columns,
              {
                title: "",
                dataIndex: "",
                key: "",
                render: (u) => (
                  <p
                    style={{ cursor: "pointer", color: "#BC2323" }}
                    onClick={() => {
                      showDeleteConfirm(u);
                    }}
                  >
                    Remove
                  </p>
                ),
              },
            ]}
            dataSource={role.privileges.map((l) => {
              return { ...l.privilege, key: l.privilege.id };
            })}
            rowSelection={{ type: "checkbox", ...rowSelection }}
            size="small"
            style={{ width: "100%" }}
            style={{ overflowX: "scroll" }}
            pagination={{
              onChange: async (page) => {
                await getPrivileges(page);
              },
              pageSize: 10,
            }}
          />
          <h2 style={{ fontSize: "20px", fontWeight: "700" }}>
            All privileges
          </h2>
          <Table
            loading={loading}
            columns={columns}
            dataSource={list.map((l) => {
              return { ...l, key: l.id };
            })}
            rowSelection={{ type: "checkbox", ...rowSelection }}
            size="small"
            style={{ width: "100%" }}
            style={{ overflowX: "scroll" }}
            pagination={{
              onChange: async (page) => {
                await getPrivileges(page);
              },
              pageSize: 10,
            }}
          />
          <Button
            loading={loading}
            type="primary"
            onClick={() => addRolePrivilege(role, selectedRowKeys)}
          >
            Confirm
          </Button>
        </>
      )}
    </div>
  );
};
export default Roles;
